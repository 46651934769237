@import "../mixins.scss";

.controls {
  container-type: inline-size;
  display: flex;
  align-self: flex-start;
  flex-basis: 50%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  list-style: none;
  padding: 5px;
  li {
    width: 60%;
    display: flex;
    flex-direction: row;
    flex-basis: 47%;
    height: 30px;
    justify-content: space-between;
    align-items: center;
    padding: 8px 5px;
    font-size: 13px;
    @include tileGlass();
  }
}

@container (max-width: 600px) {
  .controls li {
    flex-direction: column;
  }
}
@container (max-width:500px) {
  .controls li {
    flex-direction: row ;
    flex-basis: 100%;
  }
}
