@mixin tileGlass() {
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.1)
  );
  border-radius: 10px;
  box-shadow: 1px 1px 3px 0px black;
  backdrop-filter: blur(10px);
}
