@import "../mixins.scss";

.anchorsList {
  box-sizing: border-box;
  flex-basis: 50%;
  min-width: 50%;
  max-height: 250px;
  overflow-y: auto;
  word-break: break-word;
  padding: 5px;

  @media screen and (max-width: 700px) {
    max-height: 400px;
  }
}
