@import "./mixins.scss";

button {
  border-radius: 10px;
  padding: 7px;
  border: 1px solid var(--tial-color-1);
  background-color: var(--tial-text-marker-text-color);
  color: var(--tial-color-1);
  transition: color 250ms linear, background-color 250ms linear;

  &:hover {
    background-color: var(--tial-color-1);
    color: var(--tial-text-marker-text-color);
    cursor: pointer;
  }
}

#root {
  height: 100%;
}

.App {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: auto;
  min-height: 100%;
  padding: 10px;
}

header, .main {
  padding: 10px;
  border-radius: 10px;
  background-color:rgba(0, 0, 0, 0.6);
}

header {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  font-size: 25px;
  line-height: 1;

}

.libName {
  span {
    color: var(--tial-color-1)
  }
}

.links {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 16px;
}

.main {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  height: auto;
  min-height: 100%;
  width: 100%;  
  overflow: hidden;

  & > * {
    box-sizing: border-box;
    max-width: 52%;
    flex-basis: 46%;
  }

  @media screen and (max-width: 700px) {
    flex-direction: column;
    gap: 20px;

    & > * {
      max-width: none;
      flex-basis: 49%;
    }
  }

  & .myAnchorText {
    margin-bottom: 20px;
    padding: 0 5px 8px 5px;
  }
}

.imageWrapper {
  @include tileGlass();
  overflow: hidden;
}

.manual,
.myAnchorText {
  @include tileGlass();
}

.manual {
  margin-top: 0;
  padding-bottom: 30px;
  padding-right: 5px;
  font-size: 13px;
  text-indent: 5px;
  font-style: italic;

  mark {
    background-color: var(--tial-color-1);
    font-style: italic bold;
  }

}

.textAndControls {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.buttonsWrapper {
  position: absolute;
  left: 0;
  top: 15px;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.controlsAndAnchorListWrapper {
  position: relative;
  overflow-x: hidden;
  max-height: 50vh;

  .slider {
    display: flex;
    width: 200%;
    height: 100%;
    overflow-y: hidden;
    transition: transform 250ms ease-in-out;

    &.controlsAreVisible {
      transform: translateX(0);
    }

    &.anchorsAreVisible {
      transform: translateX(-50%);
    }
  }

  @media screen and (max-width: 700px) {
    max-height: none;
  }
}

.footerButtonsWrapper {
  display: flex;
  justify-content: space-around;
}
.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(33, 33, 33, 0.5);
  z-index: 2;

  & button {
    height: fit-content;
  }
}

.inputForm {
  width: fit-content;
  max-width: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  border-radius: 10px;
  background-color: var(--tial-text-marker-text-color);
  padding: 10px;

  textarea {
    width: 100%;
    height: 150px;
    border-radius: 10px;
    padding: 5px;
    background-color: var(--tial-color-background);
  }

  & p {
    margin: 10px 0;
    font-size: 13px;
    text-indent: 5px;
    font-style: italic;
  }
}

.imageInputWrapper {
  width: 100%;
  padding: 5px;
  overflow: hidden;

  & > * {
    margin-bottom: 20px;
  }

  .inputWrap {
    width: 400px;
    margin: auto;
    input {
      display: block;
      width: 100%;
      height: 30px;
      border: 1px solid var(--tial-color-1);
      background-color: var(--tial-color-background);
      box-sizing: border-box;
      box-shadow: none;
      border-radius: 5px;
      outline: none;
    }
  }
}

.radioButtonsWrap {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
